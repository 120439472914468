const teamNameMap: any = {
    'ARI': {teamName: 'Arizona Diamondbacks', primaryColor: 'rgb(167,25,48)', secondaryColor: 'rgb(48,206,216)'},
    'ATL': {teamName: 'Atlanta Braves', primaryColor: 'rgb(206,17,65)', secondaryColor: 'rgb(19, 39, 79)'},
    'BAL': {teamName: 'Baltimore Orioles', primaryColor: 'rgb(223,70,1)', secondaryColor: 'rgb(39,37,31)'},
    'BOS': {teamName: 'Boston Red Sox', primaryColor: 'rgb(189, 48, 57)', secondaryColor: 'rgb(12,35,64)'},
    'CHC': {teamName: 'Chicago Cubs', primaryColor: 'rgb(14,51,134)', secondaryColor: 'rgb(204,52,51)'},
    'CHW': {teamName: 'Chicago White Sox', primaryColor: 'rgb(39,37,31)', secondaryColor: 'rgb(196,206,212)'},
    'CIN': {teamName: 'Cincinnati Reds', primaryColor: 'rgb(198,1,31)', secondaryColor: 'rgb(0,0,0)'},
    'CLE': {teamName: 'Cleveland', primaryColor: 'rgb(12,35,64)', secondaryColor: 'rgb(227,25,55)'},
    'COL': {teamName: 'Colorado Rockies', primaryColor: 'rgb(51,0,111)', secondaryColor: 'rgb(196,206,212)'},
    'DET': {teamName: 'Detroit Tigers', primaryColor: 'rgb(12,35,64)', secondaryColor: 'rgb(250,70,22)'},
    'HOU': {teamName: 'Houston Astros', primaryColor: 'rgb(0,45,98)', secondaryColor: 'rgb(235,110,31)'},
    'KCR': {teamName: 'Kansas City Royals', primaryColor: 'rgb(0,70,135)', secondaryColor: 'rgb(189,155,96)'},
    'LAA': {teamName: 'Los Angeles Angels', primaryColor: 'rgb(0,50,99)', secondaryColor: 'rgb(186,0,33)'},
    'LAD': {teamName: 'Los Angeles Dodgers', primaryColor: 'rgb(0,90,156)', secondaryColor: 'rgb(255,255,255)'},
    'MIA': {teamName: 'Miami Marlins', primaryColor: 'rgb(0,0,0)', secondaryColor: 'rgb(0,163,224)'},
    'MIL': {teamName: 'Milwuake Brewers', primaryColor: 'rgb(18, 40, 75)', secondaryColor: 'rgb(255, 197, 47)'},
    'MIN': {teamName: 'Minnesota Twins', primaryColor: 'rgb(0,43,92)', secondaryColor: 'rgb(211,17,69)'},
    'NYM': {teamName: 'New York Mets', primaryColor: 'rgb(0,45, 114)', secondaryColor: 'rgb(252,89,16)'},
    'NYY': {teamName: 'New York Yankees', primaryColor: 'rgb(12,35,64)', secondaryColor: 'rgb(228,0,44)'},
    'OAK': {teamName: 'Oakland Athletics', primaryColor: 'rgb(0,56,49)', secondaryColor: 'rgb(239,178,30)'},
    'PHI': {teamName: 'Philadelphia Phillies', primaryColor: 'rgb(232,24,40)', secondaryColor: 'rgb(0,45,114)'},
    'PIT': {teamName: 'Pittsburgh Pirates', primaryColor: 'rgb(39,37,31)', secondaryColor: 'rgb(253,184,39)'},
    'SDP': {teamName: 'San Diego Padres', primaryColor: 'rgb(47,36,29)', secondaryColor: 'rgb(255,196,37)'},
    'SEA': {teamName: 'Seattle Mariners', primaryColor: 'rgb(0,92,92)', secondaryColor: 'rgb(196,206,212)'},
    'SFG': {teamName: 'San Francisco Giants', primaryColor: 'rgb(39,37,31)', secondaryColor: 'rgb(253,90,30)'},
    'STL': {teamName: 'St. Louis Cardinals', primaryColor: 'rgb(196,30,58)', secondaryColor: 'rgb(12,35,64)'},
    'TBR': {teamName: 'Tampa Bay Rays', primaryColor: 'rgb(9,44,92)', secondaryColor: 'rgb(143,188,230)'},
    'TEX': {teamName: 'Texas Rangers', primaryColor: 'rgb(0,50,120)', secondaryColor: 'rgb(192,17,31)'},
    'TOR': {teamName: 'Toronto Blue Jays', primaryColor: 'rgb(0,107,166)', secondaryColor: 'rgb(29,45,92)'},
    'WSN': {teamName: 'Washington Nationals', primaryColor: 'rgb(20,34,90)', secondaryColor: 'rgb(171,0,3)'}
};

export default teamNameMap;